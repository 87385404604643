import moment from "moment-timezone";
import * as yup from "yup";
import { ZonedDate } from "@teamrota/rota-common";

const isSameOrBefore = (startTime, endTime) => {
  return moment(startTime, "HH:mm").isSameOrBefore(moment(endTime, "HH:mm"));
};

const isWithinMaxDuration = (startDateTime, endDateTime) => {
  const durationHours = moment(endDateTime).diff(
    moment(startDateTime),
    "hours"
  );
  return durationHours <= 36;
};

const REQUIRED_MESSAGE = "This field is required.";
const MAX_DURATION_MESSAGE = "Shift duration cannot be greater than 36 hours.";

export const validationSchema = yup.object().shape({
  venueId: yup.string().required(REQUIRED_MESSAGE),
  subvenueId: yup.string(),
  purchaseOrderNumber: yup.string().nullable(),
  breakMinutes: yup
    .string()
    .required(
      "Break is required and cannot be empty. Enter 0 if there is no break."
    )
    .matches(/^\d+$/, "Break must be a non-negative number.")
    .test(
      "break_minutes_test",
      "Break cannot be longer than the length of the shift.",
      function(value) {
        const { startDate, startTime, endDate, endTime } = this.parent;
        if (
          !startDate ||
          !startTime ||
          !endDate ||
          !endTime ||
          value === undefined
        ) {
          return true;
        }

        const breakMinutes = parseInt(value, 10);
        if (isNaN(breakMinutes)) {
          return this.createError({
            message: "Break must be a valid number."
          });
        }
        if (breakMinutes < 0) {
          return this.createError({
            message: "Break cannot be negative."
          });
        }

        const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
        const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
        const shiftDurationMinutes = moment(endDateTime).diff(
          moment(startDateTime),
          "minutes"
        );

        return breakMinutes <= shiftDurationMinutes;
      }
    ),

  startTime: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ensure to use the format HH:mm"
    )
    .test(
      "start_time_test",
      "Shift start time must be before the shift end time.",
      function(value) {
        const { endTime, endDate, startDate } = this.parent;
        const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
        const startDateTime = new ZonedDate(`${startDate} ${value}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    )
    .test("max_duration_test", MAX_DURATION_MESSAGE, function(value) {
      const { endTime, endDate, startDate } = this.parent;
      if (!endTime || !endDate || !startDate) return true; // Skip validation if not all values are present
      const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
      const startDateTime = new ZonedDate(`${startDate} ${value}`);
      return isWithinMaxDuration(startDateTime, endDateTime);
    }),
  startDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      "start_date_test",
      "Shift start date must be same or before the shift end date.",
      function(value) {
        const { endTime, endDate, startTime } = this.parent;
        const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
        const startDateTime = new ZonedDate(`${value} ${startTime}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    )
    .test("max_duration_test", MAX_DURATION_MESSAGE, function(value) {
      const { endTime, endDate, startTime } = this.parent;
      if (!endTime || !endDate || !startTime) return true; // Skip validation if not all values are present
      const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
      const startDateTime = new ZonedDate(`${value} ${startTime}`);
      return isWithinMaxDuration(startDateTime, endDateTime);
    }),
  endDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      "end_date_test",
      "Shift end date must be same or after the shift start date.",
      function(value) {
        const { endTime, startDate, startTime } = this.parent;
        const endDateTime = new ZonedDate(`${value} ${endTime}`);
        const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    )
    .test("max_duration_test", MAX_DURATION_MESSAGE, function(value) {
      const { endTime, startDate, startTime } = this.parent;
      if (!endTime || !startDate || !startTime) return true; // Skip validation if not all values are present
      const endDateTime = new ZonedDate(`${value} ${endTime}`);
      const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
      return isWithinMaxDuration(startDateTime, endDateTime);
    }),
  endTime: yup
    .string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ensure to use the format HH:mm"
    )
    .test(
      "end_time_test",
      "Shift end time must be after the shift start time.",
      function(value) {
        const { endDate, startDate, startTime } = this.parent;
        const endDateTime = new ZonedDate(`${endDate} ${value}`);
        const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    )
    .test("max_duration_test", MAX_DURATION_MESSAGE, function(value) {
      const { endDate, startDate, startTime } = this.parent;
      if (!endDate || !startDate || !startTime) return true; // Skip validation if not all values are present
      const endDateTime = new ZonedDate(`${endDate} ${value}`);
      const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
      return isWithinMaxDuration(startDateTime, endDateTime);
    })
    .required(REQUIRED_MESSAGE),
  approveMember: yup.boolean().nullable(),
  feedbackForMember: yup.string().when("approveMember", {
    is: true,
    then: schema =>
      schema.required("Feedback is required when approving member."),
    otherwise: schema => schema.transform(() => undefined)
  })
});
