import React from "react";
import { get } from "lodash/fp";
import { flow } from "lodash";
import { Role } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import Modal from "~/src/components/modal";
import Tabs from "~/src/containers/modals/components/tabs";
import History from "~/src/containers/modals/components/history";
import { showShiftTimeRange } from "~/src/utils/formatting";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { TIMESHEET_MEMBER_STATUS_LABLES } from "~/src/consts";
import TimeDisplay from "~/src/components/shift-time-display";

import getTimesheetMember from "./graphql/get-timesheet-member";
import getAccountVenues from "./graphql/get-account-venues";
import ViewEditTimesheetFormComponent from "./view-edit-timesheet-form";
import ModalContent from "../components/modal-content";
import Title from "../components/title";
import Text from "../components/text";
import { LeftPane, RightPane } from "../components/content-wrapper";
import {
  TIMESHEET_MEMBER_STATUS,
  TIMESHEET_STATUS
} from "@teamrota/rota-common";

const ViewEditTimesheetComponent = props => {
  if (!props.isOpen) return false;

  const timesheetStatus = get("timesheetMemberRow.timesheet.status", props);
  const status = get("timesheetMemberRow.status", props);

  const tabs = props.isOpen
    ? [
        {
          name: "Info",
          content: (
            <ViewEditTimesheetFormComponent
              timesheetMemberRow={props.timesheetMemberRow}
              venues={props.venues}
              timesheetStatus={timesheetStatus}
            />
          )
        },
        ...(props?.auth?.hasRole(Role?.SHIFTS_HISTORY)
          ? [
              {
                name: "History",
                content: (
                  <History logs={get("timesheetMemberRow.logs", props)} />
                )
              }
            ]
          : [])
      ]
    : null;

  const startTime = get("timesheetMemberRow.scheduledStartTime", props);
  const endTime = get("timesheetMemberRow.scheduledEndTime", props);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={props.isLoading}
    >
      <ModalContent title="Timesheet Details">
        <LeftPane>
          <Title marginBottom>Summary</Title>
          <Text>{get("timesheetMemberRow.timesheet.accountName", props)}</Text>
          <Text>{`${get("timesheetMemberRow.role.name", props)}`}</Text>
          <Text>
            <TimeDisplay
              displayDate={showShiftTimeRange(startTime, endTime, {
                includeYear: true
              })}
              startTime={startTime}
              endTime={endTime}
            />
          </Text>

          <Title marginTop>Status</Title>
          <Text
            style={
              status === TIMESHEET_MEMBER_STATUS.APPROVED ||
              timesheetStatus === TIMESHEET_STATUS.LOCKED
                ? { fontWeight: "bold", color: "red" }
                : {}
            }
          >
            {timesheetStatus === TIMESHEET_STATUS.LOCKED
              ? "Locked"
              : TIMESHEET_MEMBER_STATUS_LABLES[status]}
          </Text>
        </LeftPane>
        <RightPane>
          <Tabs tabs={tabs} defaultTab="Info" />
        </RightPane>
      </ModalContent>
    </Modal>
  );
};

export default flow(
  getTimesheetMember,
  getAccountVenues,
  getProfile,
  withAuth
)(ViewEditTimesheetComponent);
