import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { flow } from "lodash";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import LogFragment from "~/src/graphql/fragments/log/log.fragment";

export const GET_TIMESHEET_MEMBER = addAuthVars(gql`
  ${LogFragment}
  query getTimesheetMember($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      timesheetMemberRow {
        id
        role {
          id
          name
        }
        venue {
          id
          name
          address
        }
        subvenue {
          id
          name
        }
        timesheet {
          id
          accountId
          accountName
          status
        }
        breakMinutes
        status
        scheduledStartTime
        scheduledEndTime
        purchaseOrderNumber
        purchaseOrderNumber
        ... on TimesheetMemberRow {
          logs {
            ...LogFragment
          }
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_TIMESHEET_MEMBER, {
    skip: props => !props.isOpen,
    props: handleErrors(({ data: { loading, booking } }) => ({
      isLoading: loading,
      timesheetMemberRow: booking?.timesheetMemberRow
    })),
    options: ({ auth, bookingId }) => ({
      fetchPolicy: "network-only",
      variables: auth.addVals(GET_TIMESHEET_MEMBER, { bookingId })
    })
  }),
  withAuth
);
