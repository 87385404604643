import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const UPDATE_TIMESHEET_MEMBER = gql`
  mutation updateTimesheetMember(
    $id: ID!
    $scheduledStartTime: String
    $scheduledEndTime: String
    $venueId: ID
    $subvenueId: Int
    $breakMinutes: Int
    $purchaseOrderNumber: String
    $approveMember: Boolean
    $feedbackForMember: String
  ) {
    updateTimesheetMember(
      id: $id
      scheduledStartTime: $scheduledStartTime
      scheduledEndTime: $scheduledEndTime
      venueId: $venueId
      subvenueId: $subvenueId
      isProvider: true
      breakMinutes: $breakMinutes
      purchaseOrderNumber: $purchaseOrderNumber
      approveMember: $approveMember
      feedbackForMember: $feedbackForMember
    ) {
      id
    }
  }
`;

const updateTimsheetMember = graphql(UPDATE_TIMESHEET_MEMBER, {
  name: "updateTimesheetMember",
  props: props => ({
    updateTimesheetMember: async (id, values, queryToRefetch) => {
      const updateTimesheetMemberResult = await props.updateTimesheetMember({
        variables: { ...values, id },
        refetchQueries: [queryToRefetch]
      });
      return updateTimesheetMemberResult.data.updateTimesheetMember;
    }
  })
});

export default updateTimsheetMember;
